import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Seo from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import { hex2rgba } from "../../styles/tools"
import Row from "../../styles/Row"

const OurStoryPageWrapper = styled(PageWrapper)`
  padding-top: 0;

  color: ${props => props.theme.color.primary};
`
const NarrowRow = styled(Row)`
  margin-bottom: 3rem;
  max-width: 900px;

  text-align: center;

  a {
    text-decoration: underline;
  }

  .gatsby-image-wrapper {
    margin: 0 0 2rem;

    box-shadow: 0 2px 12px ${props => hex2rgba(props.theme.color.primary, 0.2)};

    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
  }
`

export default function OurStoryPage() {
  const images = useStaticQuery(graphql`
    query ourStoryImages {
      header: file(relativePath: { eq: "our-story-header.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1000, placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <OurStoryPageWrapper>
      <Seo title="Our Story" />
      <NarrowRow>
        <GatsbyImage image={images.header.childImageSharp.gatsbyImageData} />

        <h1>Our Story - WTF?! (What the f'real?!)</h1>

        <section>
          <p>
            You've discovered the greatest tasting frozen beverages on the
            planet! But how did we get our start?
          </p>
        </section>

        <section>
          <p>
            Back in the day, our founder Jim came up with the idea to build a
            blender that created happiness in a cup for people everywhere, with
            real milk and honest ingredients. In 2003 the first f'real blender
            was installed at a store in Tulsa, OK.
          </p>
        </section>

        <section>
          <p>
            We've come a long way since then, but our shakes and smoothies are
            still a chill and tasty blend-it-yourself (BIY) snack that you, yes,
            YOU, get to blend yourself at the f'real machine: Pick your flavor,
            pick your thickness and get ready for a f'really good experience.
          </p>
        </section>

        <section>
          <p>With over 20K locations worldwide, you can't miss us.</p>
        </section>
      </NarrowRow>
    </OurStoryPageWrapper>
  )
}
